export function table_price_tomorrow(data_hours, element) {
  const container = document.querySelector('.table-next-day');
  const table_grid = document.querySelector(element);
  let title;

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  };

  if (!document.querySelector('.container-table-next-day-title')) {
    title = `<summary><h2 class="container-table-next-day-title"><span style="font-weight: normal; pointer-events:none;">Mañana</span> ${tomorrow.toLocaleDateString(
      'es-ES',
      options
    )}</h2></summary>`;
    container.insertAdjacentHTML('afterbegin', title);
  }

  for (let element of data_hours) {
    const { price, hour, zone } = element;
    const transform_hour = hour < 10 ? `0${hour}` : `${hour}`;

    const block_hour = `<div class="container-table-price-element">
      <span class="container-table-price-element-hour ${zone}">
        ${transform_hour}:00 - ${transform_hour}:59
      </span>
      <span class="container-table-price-element-price">
        ${price} €/kWh
      </span>
    </div>`;

    table_grid.insertAdjacentHTML('beforeend', block_hour);
  }
}

export function table_price(data_hours, element) {
  const container = document.querySelector(element);

  const today = new Date();

  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  };

  const getElementDate = document.getElementById('section-subtitle-date');
  getElementDate.textContent = `${today.toLocaleDateString('es-ES', options)}`;

  for (let elements of data_hours) {
    const { price, hour, zone, hourHasPassed } = elements;
    const transform_hour_ = hour < 10 ? `0${hour}` : `${hour}`;
    const hour_has_passed_class = hourHasPassed && 'element-hour-disabled';

    const block_hour = `<div class="${hour_has_passed_class} container-table-price-element">
      <span class="container-table-price-element-hour ${zone}">
        ${transform_hour_}:00 - ${transform_hour_}:59
      </span>
      <span class="container-table-price-element-price">
        ${price} €/kWh
      </span>
    </div>`;
    container.insertAdjacentHTML('beforeend', block_hour);
  }
}
